<template>
  <div class="home">
    <van-nav-bar title="办公"  id="reset" />
    <div class="day" @click=routeTo()>
      <div class="day-item">
        <img src="../static/办公/xierizhi.png" alt="">

      </div>
    </div>
	
	
	<van-swipe-cell v-for="(item,index) in loglist" :key="index" class="icos" >
	     <div class="items">
	      <div class="img1"></div>
	      <div class="txt">
	        <div class="t1">
	          <p class="tname">{{item.role_info.full_name}}</p>
	          <p class="tc">{{item.subject}}</p>
	        </div>
	        <div class="t2">
	          <p class="tc">{{item.content}}</p>
	        </div>
	      </div>
	    </div>
	  <template #right>
	    <!-- <van-button square type="danger" text="删除"  class="delete-button"/> -->
	  </template>
	</van-swipe-cell>
  </div>
</template>

<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  loglist:{},
	  
    };
  },created () {
    this.logData();
    // this.getHistoryData()
  },
methods:{
	routeTo()
	{
 		// console.log(customer_id);
		this.$router.push({
		  path: `/xierizhi`,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 
 logData(){
 	let param = new URLSearchParams();
 	
 	// param.append("by", 'dynamic');
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=log&a=index',param).then(res1 => {
 		if (res1.data.status === -1) {
 
 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res1.data.status === 1) {
 			
 			this.loglist = res1.data.data;
 		
 		}
 		console.log(res1);
 	}).catch(function (error){
 		console.log(error);
 	});
 }
 
},

};
</script>

<style lang="scss" scoped>

#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
.txt {
  margin-left: 10px;
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  // justify-content: center;
  align-items: center;
  p {
    margin: 5px 0;
  }
  .tname {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .tc {
    font-size: 11px;
    font-weight: 400;
    color: #cccccc;
  }
  .t1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.items {
  // width: 355px;
  height: 75px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  img {
    margin-left: 15px;
  }
  .img {
    width: 50px;
    height: 50px;
    background: #eca756;
    border-radius: 50%;
    margin-left: 15px;
  }
  .img1 {
    width: 50px;
    height: 50px;
    background: #7cd364;
    border-radius: 50%;
    margin-left: 15px;
  }
}
#reset ::v-deep .van-nav-bar__title{
    color: #ffffff  !important;
}
.van-nav-bar{
    background: #1288f4;
   
}
.home {
  height: 100vh;
  background: #f2f2f2;
}
.day {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 22px;
  .day-item {
    width: 57px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #14afb1;
    border-radius: 12px;
    img {
      display: block;
      height: 34px;
      width: 34px;
    }
  }
}
</style>